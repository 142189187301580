import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import Chartist from 'react-chartist';
import useSWR from 'swr';
import Header from 'components/Header';
import { DashboardPlaceholder } from 'components/Placeholders';
import { BRL } from 'utils';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

function Dashboard({ onSelectOrder }) {
  const [ticketsChartData, setTicketsChartData] = useState(null);
  const [paymentMethodChartData, setPaymentMethodChartData] = useState(null);

  const { eventId } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();

  const { data, error } = useSWR(() => '/promoter/reports/' + eventId, {
    onError: (error) => {
      if (error.response?.status === 401) {
        localStorage.removeItem('at');
        dispatch({ type: 'LOGOUT' });
        history.push('/');
      }
    },
  });

  useEffect(() => {
    if (data?.tickets_grouped_by_ticket?.length > 0) {
      setTicketsChartData({
        series: data.tickets_grouped_by_ticket,
        labels: data.tickets_grouped_by_ticket,
      });
    }

    if (data?.orders_grouped_by_payment_method?.length > 0) {
      setPaymentMethodChartData({
        series: data.orders_grouped_by_payment_method,
        labels: data.orders_grouped_by_payment_method,
      });
    }
  }, [data]);

  return (
    <div id="reports" className="page">
      <Header
        title={data?.event.name ?? 'Carregando relatórios...'}
        description="Vendas e estatísticas do evento selecionado."
        goBack
      />
      {error ? (
        <div className="alert alert-danger">
          Não foi possível carregar os eventos. Tente novamente.
        </div>
      ) : !data ? (
        <DashboardPlaceholder />
      ) : (
        <>
          <div className="statistics">
            <div className="statistic">
              <div className="title">Valor faturado</div>
              <div className="value">{BRL(data.statistics?.tickets?.amount ?? 0)}</div>
              <div className="spotlight">em {data.statistics?.orders_sold ?? 0} vendas</div>
            </div>
            <div className="statistic">
              <div className="title">Aguardando pagamento</div>
              <div className="value">
                {BRL(data.statistics?.orders_awaiting_payment_amount ?? 0)}
              </div>
              <div className="spotlight">
                em {data.statistics?.orders_awaiting_payment ?? 0} vendas
              </div>
            </div>
          </div>
          <div className="statistics">
            <div className="statistic">
              <div className="title">Cortesias entregues</div>
              <div className="value">{data.statistics?.courtesies ?? 0}</div>
            </div>
          </div>
          <div className="panel orders">
            <div className="panel-header">Vendas</div>
            <div className="list">
              {data.orders.length === 0 ? (
                <div className="list-item">
                  Você não possui nenhuma venda registrada para este evento.
                </div>
              ) : (
                data.orders.map((order) => (
                  <Link
                    onClick={() => onSelectOrder(order)}
                    to={'/reports/order/' + order.id}
                    key={order.id}
                    className="list-item"
                  >
                    <div className="tw-flex tw-flex-auto tw-items-center tw-gap-4">
                      <div className="order-id">#{order.id}</div>
                      <div>
                        <div>
                          <strong>{order.tickets_count} </strong> ingresso(s) vendido(s) no{' '}
                          <strong>{order.payment_method_name}</strong>
                        </div>
                        <div>
                          Status: <strong>{order.status_name} </strong>
                        </div>
                      </div>
                      <div className="tw-ml-auto">
                        <span className="hour">{dayjs(order.created_at).fromNow()}</span>
                      </div>
                    </div>
                  </Link>
                ))
              )}
            </div>
          </div>
          {ticketsChartData && (
            <PieChart data={ticketsChartData} title="Vendas por ingresso" label="ingresso(s)" />
          )}
          {paymentMethodChartData && (
            <PieChart
              data={paymentMethodChartData}
              title="Vendas por método de pagamento"
              label="venda(s)"
            />
          )}
        </>
      )}
    </div>
  );
}

function PieChart({ data, title, label }) {
  return (
    <div className="panel reports">
      <div className="panel-header">{title}</div>
      <div className="panel-body">
        <Chartist
          data={data}
          options={{
            labelInterpolationFnc: (_, index) => index + 1 + 'º',
          }}
          type="Pie"
          className="chart"
        />
        <div className="list">
          {data.series.map((data, index) => (
            <div key={index} className="list-item">
              <p>
                <strong>{index + 1}º.</strong> {data.name}
              </p>
              <p className="margin-bottom-0">
                {data.quantity} {label} - <strong>{BRL(data.value)}</strong>
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
