import React, { useState, useEffect } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { ref, object, string } from 'yup';
import axios from 'modules/axios';
import Field from 'components/Field';

const initialValues = {
  email: '',
  password: '',
  password_confirmation: '',
};

const validationSchema = object().shape({
  email: string().required('Preencha seu e-mail.').email('Preencha com um e-mail válido'),
  password: string().required('Preencher sua senha.'),
  password_confirmation: string()
    .required('Preencher a confirmação de senha.')
    .oneOf([ref('password'), null], 'Confirmação de senha deve ser igual a senha.'),
});

function ResetPassword() {
  const [formValues, setFormValues] = useState(initialValues);
  const [searchParams, setSearchParams] = useState({ token: null });

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    setFormValues((prevFormValues) => ({ ...prevFormValues, email: params.email ?? '' }));
    setSearchParams((prevSearchParams) => ({ ...prevSearchParams, token: params.token ?? '' }));
  }, [location]);

  function handleOnSubmit(values, { setSubmitting, setFieldError, resetForm }) {
    axios
      .post('/promoter/reset-password', {
        ...values,
        token: searchParams.token,
      })
      .then(() => {
        resetForm();
        history.push('/login?status=PASSWORD_RESET_SUCCESS');
      })
      .catch((error) => {
        if (error.response?.status === 422) {
          Object.keys(error.response.data.errors).forEach((inputName) => {
            const errorMessage = error.response.data.errors[inputName].join('\r\n');
            setFieldError(inputName, errorMessage);
          });
        }

        setSubmitting(false);
      })
      .finally(() => setSubmitting(false));
  }

  // redirect to index if has not token param.
  if (searchParams.token !== null && searchParams.token === '') {
    return <Redirect to="/" />;
  }

  return (
    <div id="login">
      <div className="content">
        <h1 className="title" style={{ marginTop: 0, marginBottom: '0.2rem' }}>
          Redefinir senha
        </h1>
        <h5 className="description">Digite seu e-mail e sua nova senha para redefini-la.</h5>
        <Formik
          initialValues={formValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field type="email" name="email" placeholder="E-mail" />
              <div className="columns">
                <div className="column col-6">
                  <Field
                    label="Nova senha"
                    type="password"
                    name="password"
                    placeholder="*****"
                    autoComplete="new-password"
                  />
                </div>
                <div className="column col-6">
                  <Field
                    label="Confirme a senha"
                    type="password"
                    name="password_confirmation"
                    placeholder="*****"
                    autoComplete="new-password"
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                {isSubmitting ? 'Alterando senha...' : 'Alterar senha'}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ResetPassword;
