import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Formik, Form, FieldArray } from 'formik';
import { array, object, string } from 'yup';
import useTitle from 'hooks/useTitle';
import Header from 'components/Header';
import Field, { MaskedField } from 'components/Field';

function Attendees() {
  useTitle('Participantes');

  const { paymentMethod, tickets } = useSelector(({ order }) => order);
  const dispatch = useDispatch();
  const history = useHistory();

  const validationSchema = object().shape({
    tickets: array().of(
      object().shape({
        user_first_name: string().required('Preencha o campo nome.'),
        user_last_name: string().required('Preencha o campo sobrenome.'),
        user_email: string()
          .required('Preencha o campo e-mail.')
          .email('Preencha com um e-mail válido.'),
        user_phone: string()
          .min(14, 'Informe um número de telefone válido')
          .max(15, 'Informe um número de telefone válido'),
      })
      
    ),
  });

  function handleOnSubmit() {
    if (paymentMethod.constant === 'BOLETO') {
      history.push('/order/boleto');
    } else if (paymentMethod.constant === 'PIX') {
      history.push('/order/pix');
    } else if (paymentMethod.constant === 'CREDIT_CARD') {
      history.push('/order/credit-card');
    } else {
      history.push('/order/checkout');
    }
  }

  if (tickets.length === 0) {
    return <Redirect to="/order" />;
  }

  return (
    <div className="page">
      <Header
        title="Participantes"
        description="Preencha os dados dos usuários dos ingressos."
        goBack
      />
      <Formik
        initialValues={{ tickets }}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ values, ...formik }) => {
          return (
            <Form>
              <FieldArray
                name="tickets"
                render={() =>
                  values.tickets.map((ticket, index) => (
                    <div key={index} className="panel">
                      <div className="panel-header">
                        Nº {index + 1} - {ticket.name}
                      </div>
                      <div className="panel-body">
                        <div className="columns">
                          <div className="column col-6">
                            <Field
                              type="text"
                              label="Nome"
                              name={`tickets.${index}.user_first_name`}
                              onChange={({ target: { name, value } }) => {
                                formik.setFieldValue(name, value);
                                dispatch({
                                  type: 'UPDATE_ORDER_TICKET',
                                  index,
                                  name: 'user_first_name',
                                  value,
                                });
                              }}
                              placeholder="William"
                            />
                          </div>
                          <div className="column col-6">
                            <Field
                              type="text"
                              label="Sobrenome"
                              name={`tickets.${index}.user_last_name`}
                              onChange={({ target: { name, value } }) => {
                                formik.setFieldValue(name, value);
                                dispatch({
                                  type: 'UPDATE_ORDER_TICKET',
                                  index,
                                  name: 'user_last_name',
                                  value,
                                });
                              }}
                              placeholder="Wallace"
                            />
                          </div>
                        </div>
                        <Field
                          type="email"
                          label="E-mail"
                          name={`tickets.${index}.user_email`}
                          onChange={({ target: { name, value } }) => {
                            formik.setFieldValue(name, value);
                            dispatch({
                              type: 'UPDATE_ORDER_TICKET',
                              index,
                              name: 'user_email',
                              value,
                            });
                          }}
                          placeholder="brave@heart.com"
                        />
                        <MaskedField
                          mask={[{ mask: '(00) 0000-0000' }, { mask: '(00) [9]0000-0000' }]}
                          label="Enviar o ingresso para o WhatsApp do cliente (opcional)"
                          onChange={({ target: { name, value } }) => {
                            formik.setFieldValue(name, value);
                            dispatch({
                              type: 'UPDATE_ORDER_TICKET',
                              index,
                              name: 'user_phone',
                              value,
                            });
                          }}
                          name={`tickets.${index}.user_phone`}
                          style={{ marginBottom: 0 }}
                          placeholder="(00) 00000-0000"
                          />
                      </div>
                    </div>
                  ))
                }
              />
              <div className="button-wrapper">
                <button type="submit" className={`btn btn-block btn-primary`}>
                  Próximo
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default Attendees;
