import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API_DOMAIN;

const instance = axios.create({
  baseURL: "https://api.paggy.fun",
  headers: {
    Accept: 'application/json',
  },
});

let requestTimeout = null;

function clearRequestTimeout() {
  if (requestTimeout) {
    clearTimeout(requestTimeout);
    requestTimeout = null;
  }
}

instance.interceptors.request.use(
  async (config) => {
    if (config.requestTimeout) {
      const cancelTokenSource = axios.CancelToken.source();
      config.cancelToken = cancelTokenSource.token;

      requestTimeout = setTimeout(() => {
        cancelTokenSource.cancel('timeout');
      }, config.requestTimeout);
    }

    const at = localStorage.getItem('at');
    if (at) {
      config.headers.Authorization = 'Bearer ' + at;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    clearRequestTimeout();

    return response;
  },
  (error) => {
    clearRequestTimeout();

    return Promise.reject(error);
  }
);

export function fetcher(url) {
  return instance.get(url).then((res) => res.data);
}

export default instance;
