import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'modules/axios';
import useSWR from 'swr';
import Header from 'components/Header';
import { OrderPlaceholder } from 'components/Placeholders';
import { BRL } from 'utils';
import dayjs from 'dayjs';

function Order({ order: propOrder }) {
  const [resending, setResending] = useState(false);
  const { orderId } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();

  const { data: order } = useSWR(() => '/promoter/order/' + orderId, {
    initialData: propOrder,
    onError: (error) => {
      if (error.response?.status === 401) {
        localStorage.removeItem('at');
        dispatch({ type: 'LOGOUT' });
        history.push('/');
      }
    },
  });

  function handleOnClickResendTickets() {
    setResending(true);

    axios
      .get('/promoter/resend-order/' + order.id)
      .then(() => {})
      .catch((error) => console.log(error.response || error.message))
      .finally(() => setResending(false));
  }

  function handleOnClickDownloadTicket(ticketId) {
    axios
      .get(`/promoter/download-ticket/${ticketId}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

        const fileName =
          response.headers['content-disposition']?.split('filename=')[1]?.slice(1, -1) ??
          `ingresso_${ticketId}`;

        const anchor = document.createElement('a');
        anchor.setAttribute('href', url);
        anchor.setAttribute('download', fileName);

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      })
      .catch((error) => console.log(error.response || error.message));
  }

  if (!order) {
    return (
      <div className="page">
        <Header
          title="Carregando pedido..."
          description="Visualize informações, baixe e reenvie os ingressos vendidos."
          goBack
        />
        <OrderPlaceholder />
      </div>
    );
  }

  const statusDate = order.status_constant === 'CANCELED' ? order.canceled_at : order.updated_at;

  const formattedStatusDate = dayjs(statusDate).format('DD/MM/YYYY HH:mm');

  return (
    <div className="page">
      <Header
        title={`Venda #${order.id}`}
        description="Visualize informações, baixe e reenvie os ingressos vendidos."
        goBack
      />
      <div className="statistics">
        <div className="statistic">
          <div className="title">Quantidade</div>
          <div className="value">{order.tickets_count}</div>
          <div className="spotlight">{order.tickets_count > 1 ? 'ingressos' : 'ingresso'}</div>
        </div>
        <div className="statistic">
          <div className="title">Valor</div>
          <div className="value">{BRL(order.display_price)}</div>
          <div className="spotlight">
            em <strong>{order.payment_method_name}</strong>
          </div>
        </div>
        <div className="statistic">
          <div className="title">Status</div>
          <div className="value">{order.status_name}</div>
          <div className="spotlight">
            em <strong>{formattedStatusDate}</strong>
          </div>
        </div>
      </div>
      {order.tickets.map((ticket, index) => (
        <div key={index} className="panel">
          <div className="panel-header">
            Nº{index + 1} - {ticket.ticket_category_name} - {ticket.name}
          </div>
          <div className="panel-body">
            <div className="columns">
              <div className="column col">
                <div className="defined-list">
                  <div className="title">Nome</div>
                  <div className="description">{ticket.user_first_name}</div>
                </div>
              </div>
              <div className="column col">
                <div className="defined-list">
                  <div className="title">Sobrenome</div>
                  <div className="description">{ticket.user_last_name}</div>
                </div>
              </div>
              <div className="column col-12">
                <div className="defined-list margin-bottom-0">
                  <div className="title">E-mail</div>
                  <div className="description">{ticket.user_email}</div>
                </div>
              </div>
            </div>
          </div>
          {order.payment_method_constant === 'CASH' && order.status_constant === 'PAID' && (
            <div className="panel-footer panel-footer-actions">
              <div className="btn-group btn-group-block">
                <button className="btn btn-dark" disabled>
                  <FontAwesomeIcon icon="envelope" fixedWidth /> Transferir ingresso
                </button>
                <button
                  onClick={() => handleOnClickDownloadTicket(ticket.id)}
                  className="btn btn-dark"
                >
                  <FontAwesomeIcon icon="file-download" fixedWidth /> Baixar ingresso
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
      {order.status_constant === 'PAID' &&
        ['CASH', 'COURTESY'].includes(order.payment_method_constant) && (
          <div className="button-wrapper">
            <button
              type="submit"
              onClick={handleOnClickResendTickets}
              className={`btn btn-block btn-primary`}
              disabled={resending}
            >
              {resending
                ? 'Reenviando...'
                : 'Reenviar ' +
                  (order.payment_method_constant === 'COURTESY' ? 'cortesias' : 'ingressos')}
            </button>
          </div>
        )}
    </div>
  );
}

export default Order;
