import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import useTitle from 'hooks/useTitle';
import Header from 'components/Header';
import Field from 'components/Field';

function CreditCard() {
  useTitle('Cartão de crédito');

  const { paymentMethod, tickets } = useSelector(({ order }) => order);
  const dispatch = useDispatch();
  const history = useHistory();

  const validationSchema = object().shape({
    email: string().required('Preencha o campo E-mail.').email('Preencha com um E-mail válido.'),
  });

  function handleOnSubmit() {
    history.push('/order/checkout');
  }

  function onChangeFieldValue(event, setFieldValue) {
    const { name, value } = event.target;

    setFieldValue(name, value);

    dispatch({
      type: 'UPDATE_ORDER_PAYMENT_METHOD_PAYER',
      fields: {
        [name]: value,
      },
    });
  }

  if (!paymentMethod || paymentMethod.constant !== 'CREDIT_CARD' || tickets.length === 0) {
    return <Redirect to="/order" />;
  }

  return (
    <div className="page">
      <Header
        title="Cartão de Crédito"
        description="Preencha os dados para o pagamento via cartão de crédito."
        goBack
      />
      <Formik
        initialValues={paymentMethod.payer}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="panel">
              <div className="panel-body">
                <Field
                  type="email"
                  label="E-mail de quem receberá o link para pagamento"
                  name="email"
                  value={paymentMethod.payer.email}
                  onChange={(event) => onChangeFieldValue(event, setFieldValue)}
                  placeholder="brave@heart.com"
                  style={{ marginBottom: 0 }}
                />
              </div>
            </div>
            <div className="button-wrapper">
              <button type="submit" className={`btn btn-block btn-primary`}>
                Próximo
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreditCard;
