import React from 'react';
import { TextRow } from 'react-placeholder/lib/placeholders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

function Header({ loading, title, description, goBack, sticky }) {
  const history = useHistory();

  function handleOnGoBack(e) {
    e.preventDefault();

    if (typeof goBack === 'string') {
      history.push(goBack);
      return;
    }

    history.goBack();
  }

  return (
    <header className={`header${sticky ? ' sticky' : ''}`}>
      <div className="display-flex align-items-center">
        {goBack && (
          <button onClick={handleOnGoBack} className="btn btn-link go-back">
            <FontAwesomeIcon icon="arrow-left" />
          </button>
        )}
        {loading ? (
          <div className="placeholder show-loading-animation">
            <TextRow color="black" style={{ marginTop: '0.1rem' }} />
          </div>
        ) : (
          <h1 className="title">{title}</h1>
        )}
      </div>
      {description && <p className="description">{description}</p>}
    </header>
  );
}

export default Header;
