import React from 'react';
import { useDispatch } from 'react-redux';
import useSWR from 'swr';
import { ListPlaceholder } from 'components/Placeholders';
import Header from 'components/Header';
import EventList from 'components/EventList';
import useTitle from 'hooks/useTitle';
import { useHistory } from 'react-router-dom';
// import simulator from 'utils/simulator';

function Events() {
  useTitle('Eventos');

  const dispatch = useDispatch();
  const history = useHistory();
  const { data: events, error } = useSWR('/promoter/events', {
    onError: (error) => {
      if (error.response?.status === 401) {
        localStorage.removeItem('at');
        dispatch({ type: 'LOGOUT' });
        history.push('/');
      }
    },
  });

  // React.useEffect(() => {
  //   events && simulator(events, history);
  // }, [events, history]);

  function handleOnSelectEvent(event) {
    dispatch({ type: 'SET_ORDER_EVENT', event });
  }

  return (
    <section id="events" className="page">
      <Header title="Vender" description="Selecione um dos eventos para inciar a venda." />
      {error ? (
        <div className="alert alert-danger">
          Não foi possível carregar os eventos. Tente novamente.
        </div>
      ) : !events ? (
        <ListPlaceholder />
      ) : events.length === 0 ? (
        <div className="alert alert-info">Sua conta não está associada a nenhum evento.</div>
      ) : (
        <EventList
          events={events}
          linkTo="/order/payment-methods"
          onSelectEvent={handleOnSelectEvent}
        />
      )}
    </section>
  );
}

export default Events;
