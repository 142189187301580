import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import useTitle from 'hooks/useTitle';
import Header from 'components/Header';
import { ListPlaceholder } from 'components/Placeholders';
import { BRL } from 'utils';

function Cart() {
  useTitle('Selecionar ingressos');

  const { event, paymentMethod, ticketCategories, cart, quantity } = useSelector(
    ({ order }) => order
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const [status, setStatus] = React.useState('LOADING');
  const [statusMessage, setStatusMessage] = React.useState('');

  React.useEffect(() => {
    if (
      paymentMethod.constant === 'BOLETO' &&
      dayjs().add(4, 'day').isAfter(dayjs(event.starts_at))
    ) {
      setStatus('ERROR');
      setStatusMessage('O pagamento por boleto só é possível até 4 dias antes do evento.');
      return;
    }

    if (cart && Object.keys(cart).length === 0) {
      setStatus('ERROR');
      setStatusMessage(`Nenhum ingresso disponível em ${paymentMethod.name}.`);
      return;
    }

    setStatus('READY');
  }, [event, cart, paymentMethod]);

  function onChangeTicketQuantity(ticketId, accountType) {
    if (
      accountType === 'increase' &&
      (cart[ticketId].quantity >= cart[ticketId].maximum_quantity ||
        cart[ticketId].quantity >= cart[ticketId].original_available_quantity)
    ) {
      return;
    } else if (accountType === 'decrease' && cart[ticketId].quantity <= 0) {
      return;
    }

    dispatch({ type: 'UPDATE_ORDER_CART_TICKET', ticketId, accountType });
  }

  function handleOnSubmit() {
    history.push('/order/attendees');
  }

  if (!event && !paymentMethod) {
    return <Redirect to="/order" />;
  }

  return (
    <div className="page">
      <Header title={event.name} description="Selecione os ingressos para venda." goBack />
      {status === 'LOADING' ? (
        <ListPlaceholder />
      ) : status === 'ERROR' ? (
        <div className="alert alert-sm alert-error">{statusMessage}</div>
      ) : (
        <>
          <div className="cart">
            {ticketCategories.map((ticketCategory) => (
              <div key={ticketCategory.id} className="category">
                <p className="category-title">{ticketCategory.name}</p>
                {ticketCategory.tickets.map((ticket) => (
                  <div key={ticket.id} className="ticket">
                    <div className="ticket-info">
                      <div className="ticket-title">{ticket.name}</div>
                      <div className="ticket-price">
                        {BRL(ticket.price * ticket.minimum_quantity)}
                      </div>
                      {cart[ticket.id]?.hasOwnProperty('available_quantity') && (
                        <div className="available-quantity">
                          {cart[ticket.id]?.available_quantity} disponíveis
                        </div>
                      )}
                    </div>
                    <div className="selector">
                      <button
                        onClick={() => onChangeTicketQuantity(ticket.id, 'decrease')}
                        disabled={cart[ticket.id]?.quantity <= 0}
                        className="btn btn-outline-light"
                      >
                        <FontAwesomeIcon icon="angle-down" />
                      </button>
                      <div className="quantity">{cart[ticket.id]?.quantity}</div>
                      <button
                        onClick={() => onChangeTicketQuantity(ticket.id, 'increase')}
                        disabled={cart[ticket.id]?.quantity >= cart[ticket.id]?.maximum_quantity}
                        className="btn btn-outline-primary"
                      >
                        <FontAwesomeIcon icon="angle-up" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="button-wrapper">
            <button
              onClick={handleOnSubmit}
              className={`btn btn-block btn-primary ${quantity === 0 ? 'disabled' : ''}`}
            >
              {quantity === 0 ? 'Selecione pelo menos um ingresso' : 'Próximo'}
            </button>
          </div>
        </>
      )}
    </div>
  );
}

Cart.whyDidYouRender = true;

export default Cart;
