export function removePropertyFromObject(property, object) {
  // eslint-disable-next-line
  const { nextObject, [property]: removedProperty } = object;

  return nextObject;
}

export function BRL(value = '0') {
  if (typeof value === 'string') value = +value;

  return value
    .toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
    .replace(/\s/g, '');
}
