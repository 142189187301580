import React, { useRef, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { ref, object, string } from 'yup';
import Field from 'components/Field';
import axios from 'modules/axios';

const initialValues = {
  current_password: '',
  new_password: '',
  new_password_confirmation: '',
};

const validationSchema = object().shape({
  current_password: string().required('Preencha a senha atual.'),
  new_password: string().required('Preencha a nova senha.'),
  new_password_confirmation: string()
    .required('Preencha a confirmação da nova senha.')
    .oneOf([ref('new_password'), null], 'Confirmação de senha deve ser igual a senha.'),
});

function ChangePassword() {
  const [status, setStatus] = useState('READY');

  const isMount = useRef(false);

  useEffect(() => {
    isMount.current = true;

    return () => {
      isMount.current = false;
    };
  }, []);

  useEffect(() => {
    if (status !== 'READY') {
      setTimeout(() => {
        if (isMount.current) {
          setStatus('READY');
        }
      }, 5000);
    }
  }, [status]);

  function handleOnSubmit(values, { setSubmitting, setFieldError, resetForm }) {
    axios
      .post('/promoter/change-password', values)
      .then(() => {
        setStatus('SUCCESS');
        resetForm();
      })
      .catch((error) => {
        if (error.response?.status === 422) {
          Object.keys(error.response.data.errors).forEach((inputName) => {
            const errorMessage = error.response.data.errors[inputName].join('\r\n');
            setFieldError(inputName, errorMessage);
          });
        }
      })
      .finally(() => setSubmitting(false));
  }

  return (
    <div className="panel">
      <div className="panel-header">Alterar senha</div>
      <div className="panel-body">
        {status === 'SUCCESS' && (
          <div className="alert alert-sm alert-success">Senha alterada com sucesso.</div>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="columns">
                <div className="column col-12">
                  <Field
                    label="Senha atual"
                    type="password"
                    name="current_password"
                    placeholder="*****"
                  />
                </div>
                <div className="column col-6">
                  <Field
                    label="Nova senha"
                    type="password"
                    name="new_password"
                    placeholder="*****"
                    autoComplete="new-password"
                  />
                </div>
                <div className="column col-6">
                  <Field
                    label="Confirme a senha"
                    type="password"
                    name="new_password_confirmation"
                    placeholder="*****"
                    autoComplete="new-password"
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                {isSubmitting ? 'Alterando senha...' : 'Alterar senha'}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ChangePassword;
