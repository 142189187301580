import React from 'react';
import { Field as BaseField, useField } from 'formik';
import { IMaskInput } from 'react-imask';

function Field({ label, style, className, ...props }) {
  const [field, meta] = useField(props);

  return (
    <div style={style} className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
      {label && <label className="form-label">{label}</label>}
      <BaseField className={'form-input ' + className} {...field} {...props} />
      {meta.touched && meta.error && <div className="form-input-hint">{meta.error}</div>}
    </div>
  );
}

export function MaskedField({
  mask,
  unmaskValue = false,
  label,
  name,
  value,
  style,
  className,
  placeholder,
  onChange,
}) {
  const [{ onChange: onChangeField, ...field }, meta] = useField({ name, value });

  return (
    <div style={style} className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
      {label && <label className="form-label">{label}</label>}
      <IMaskInput
        mask={mask}
        unmask={unmaskValue}
        onAccept={(value, mask, event) => {
          onChange ? onChange(event) : onChangeField(event);
        }}
        className={'form-input ' + className}
        placeholder={placeholder}
        {...field}
      />
      {meta.touched && meta.error && <div className="form-input-hint">{meta.error}</div>}
    </div>
  );
}

export default Field;
