import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChangePassword from 'pages/Profile/ChangePassword';
import axios from 'modules/axios';
import useTitle from 'hooks/useTitle';

function Profile() {
  useTitle('Minha conta');

  const user = useSelector(({ user }) => user.user);
  const dispatch = useDispatch();

  const history = useHistory();

  function handleOnClickLogout() {
    dispatch({ type: 'AUTHENTICATING' });

    axios
      .post('/promoter/logout')
      .then(() => {
        localStorage.removeItem('at');

        dispatch({ type: 'LOGOUT' });

        history.push('/');
      })
      .catch((error) => console.log(error.response || error.message));
  }

  return (
    <section className="page">
      <div className="header">
        <div className="display-flex align-items-center">
          <div className="title">
            <span className="thin">Olá,</span> {user.name.split(' ')[0]}
          </div>
          <div className="flex-align-right">
            <button onClick={handleOnClickLogout} className="btn btn-sm btn-primary">
              <FontAwesomeIcon icon="sign-out-alt" /> Sair
            </button>
          </div>
        </div>
        <div className="description">Gerencie os dados da sua conta.</div>
      </div>
      <div className="panel">
        <div className="list">
          <div className="list-item">
            <div className="defined-list margin-bottom-0">
              <div className="title">Nome</div>
              <div className="description">{user.name}</div>
            </div>
          </div>
          <div className="list-item">
            <div className="defined-list margin-bottom-0">
              <div className="title">E-mail</div>
              <div className="description">{user.email}</div>
            </div>
          </div>
          <div className="list-item">
            <div className="defined-list margin-bottom-0">
              <div className="title">Telefone</div>
              <div className="description">{user.phone ?? '-'}</div>
            </div>
          </div>
        </div>
      </div>
      <ChangePassword />
    </section>
  );
}

export default Profile;
