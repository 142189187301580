import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import axios from 'modules/axios';
import Splash from 'pages/Splash';
import Login from 'pages/Authentication/Login';
import ForgotPassword from 'pages/Authentication/ForgotPassword';
import ResetPassword from 'pages/Authentication/ResetPassword';
import Panel from 'pages/Panel';
import PageNotFound from 'pages/PageNotFound';

function Authentication() {
  const user = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  function hasAccessToken() {
    return localStorage.getItem('at') !== null;
  }

  useEffect(() => {
    if (hasAccessToken()) {
      axios
        .get('/promoter/promoter')
        .then(({ data }) => {
          dispatch({ type: 'LOGIN', user: data });
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            localStorage.removeItem('at');

            dispatch({ type: 'LOGOUT' });

            history.push('/');
          }
        });
    } else {
      dispatch({ type: 'LOGOUT' });

      if (!['/login', '/forgot-password', '/reset-password/'].includes(location.pathname)) {
        history.push('/');
      }
    }

    // eslint-disable-next-line
  }, []);

  if (user.status === 'LOGGED_IN') {
    return <Panel />;
  }

  if (user.status === 'LOGGED_OUT') {
    return (
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />}></Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="*">
          <div id="application">
            <PageNotFound />
          </div>
        </Route>
      </Switch>
    );
  }

  return <Splash />;
}

export default Authentication;
