import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import axios from 'modules/axios';
import Header from 'components/Header';
import useTitle from 'hooks/useTitle';
import { BRL } from 'utils';

function Checkout() {
  useTitle('Revisão de venda');

  const [status, setStatus] = useState('READY');
  const [statusMessage, setStatusMessage] = useState('');

  const { event, tickets, price, paymentMethod } = useSelector(({ order }) => order);
  const history = useHistory();

  const isMount = useRef(false);

  const alertRef = useRef();

  const submitButtonText = (() => {
    switch (paymentMethod.constant) {
      case 'CASH':
        return 'Enviar ingressos';
      case 'COURTESY':
        return 'Enviar cortesias';
      case 'BOLETO':
        return 'Enviar boleto';
      case 'CREDIT_CARD':
        return 'Enviar link de pagamento';
      default:
        return 'Enviar';
    }
  })();

  useEffect(() => {
    isMount.current = true;

    return () => (isMount.current = false);
  }, []);

  function handleOnSubmit() {
    setStatus('SENDING');

    const fetchData = {
      event_id: event.id,
      payment_method_id: paymentMethod.id,
      tickets,
    };

    if (['BOLETO', 'PIX', 'CREDIT_CARD'].includes(paymentMethod.constant)) {
      fetchData.payer = paymentMethod.payer;
    }

    axios
      .post('/promoter/order', fetchData)
      .then(() => {
        history.push('/order/success');
      })
      .catch((error) => {
        console.log(error.response || error.message);

        if (isMount.current) {
          let message = error.response?.data.message ?? '';

          if (error.response?.status === 422) {
            Object.keys(error.response.data.errors).forEach((inputName) => {
              message = error.response.data.errors[inputName].join('\r\n');
            });
          }

          setStatus('ERROR');
          setStatusMessage(message);

          alertRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
      });
  }

  if (tickets.length === 0) {
    return <Redirect to="/order" />;
  }

  return (
    <div id="checkout" className="page">
      <Header title="Checkout" description="Revise com atenção os dados da venda." goBack />
      <div ref={alertRef}>
        {status === 'ERROR' && <div className="alert alert-danger">{statusMessage}</div>}
      </div>
      <div className="panel">
        <div className="panel-header">Pagamento</div>
        <div className="panel-body" style={{ paddingBottom: 0 }}>
          <div className="columns">
            <div className="column col">
              <div className="defined-list">
                <div className="title">Valor</div>
                <div className="description">
                  <strong>{BRL(price)}</strong>
                </div>
              </div>
            </div>
            <div className="column col">
              <div className="defined-list">
                <div className="title">Metódo</div>
                <div className="description">{paymentMethod.name}</div>
              </div>
            </div>
          </div>
          {['BOLETO'].includes(paymentMethod.constant) && (
            <>
              <div className="columns">
                <div className="column col">
                  <div className="defined-list">
                    <div className="title">Nome completo</div>
                    <div className="description">{paymentMethod.payer.name}</div>
                  </div>
                </div>
                <div className="column col">
                  <div className="defined-list">
                    <div className="title">E-mail de pagamento</div>
                    <div className="description">{paymentMethod.payer.email}</div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column col">
                  <div className="defined-list">
                    <div className="title">Endereço</div>
                    <div className="description">
                      {`${paymentMethod.payer.cep} - ${paymentMethod.payer.city}, ${paymentMethod.payer.state}, ${paymentMethod.payer.street},
                        ${paymentMethod.payer.number}, ${paymentMethod.payer.district}`}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {['PIX', 'CREDIT_CARD'].includes(paymentMethod.constant) && (
            <div className="columns">
              <div className="column col">
                <div className="defined-list">
                  <div className="title">E-mail de pagamento</div>
                  <div className="description">{paymentMethod.payer.email}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {tickets.map((ticket, index) => (
        <div key={index} className="panel">
          <div className="panel-header">
            Nº {index + 1} - {ticket.name}
          </div>
          <div className="panel-body">
            <div className="columns">
              <div className="column col">
                <div className="defined-list">
                  <div className="title">Nome</div>
                  <div className="description">{ticket.user_first_name}</div>
                </div>
              </div>
              <div className="column col">
                <div className="defined-list">
                  <div className="title">Sobrenome</div>
                  <div className="description">{ticket.user_last_name}</div>
                </div>
              </div>
              <div className="column col-12">
                <div className="defined-list margin-bottom-0">
                  <div className="title">E-mail</div>
                  <div className="description">{ticket.user_email}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="button-wrapper">
        <button
          type="submit"
          onClick={handleOnSubmit}
          className={`btn btn-block btn-primary`}
          disabled={status === 'SENDING'}
        >
          {status === 'SENDING' ? 'Enviando...' : submitButtonText}
        </button>
      </div>
    </div>
  );
}

export default Checkout;
