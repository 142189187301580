import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faArrowLeft,
  faAngleRight,
  faAngleUp,
  faCheck,
  faCheckCircle,
  faChartBar,
  faChartColumn,
  faCreditCard,
  faDollarSign,
  faDonate,
  faEnvelope,
  faFileDownload,
  faFileInvoiceDollar,
  faGift,
  faPlus,
  faSearch,
  faShoppingCart,
  faSignOutAlt,
  faTicketAlt,
  faTimes,
  faUser,

} from '@fortawesome/free-solid-svg-icons';

library.add(
  faAngleDown,
  faArrowLeft,
  faAngleRight,
  faAngleUp,
  faCheck,
  faCheckCircle,
  faChartBar,
  faChartColumn,
  faCreditCard,
  faDollarSign,
  faDonate,
  faEnvelope,
  faFileDownload,
  faFileInvoiceDollar,
  faGift,
  faPlus,
  faSearch,
  faShoppingCart,
  faSignOutAlt,
  faTicketAlt,
  faTimes,
  faUser
);
