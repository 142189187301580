import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { fetcher } from 'modules/axios';
import store from 'state/store';
import Authentication from 'pages/Authentication';
import Maintenance from 'pages/Maintenance';

function App() {
  if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
    return <Maintenance />;
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <SWRConfig
          value={{
            fetcher: (...args) => fetcher(...args),
          }}
        >
          <Authentication />
        </SWRConfig>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
