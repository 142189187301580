import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import axios from 'modules/axios';
import Field from 'components/Field';
import logo from 'assets/images/logo-small.png';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = object().shape({
  email: string().required('Preencha seu e-mail.').email('Preencha com um e-mail válido'),
  password: string().required('Preencher sua senha.'),
});

function Login() {
  const [searchParams, setSearchParams] = useState({});
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    setSearchParams(params);
  }, [location]);

  function handleOnSubmit(values, { setSubmitting, setFieldError }) {
    axios
      .post('/promoter/login', {
        email: values.email,
        password: values.password,
      })
      .then(({ data }) => {
        localStorage.setItem('at', data.access_token);

        dispatch({
          type: 'LOGIN',
          user: data.promoter,
        });

        history.push('/');
      })
      .catch((error) => {
        if (error.response?.status === 422) {
          Object.keys(error.response.data.errors).forEach((inputName) => {
            const errorMessage = error.response.data.errors[inputName].join('\r\n');
            setFieldError(inputName, errorMessage);
          });
        }

        setSubmitting(false);
      });
  }

  return (
    <div id="login">
      <div className="content">
        <div className="logo">
          <img src={logo} alt="UP Tickets logo" />
        </div>
        <h1 className="title">Entrar</h1>
        <h5 className="description">Olá promoter, como estão as vendas?</h5>
        {searchParams.status === 'PASSWORD_RESET_SUCCESS' && (
          <div className="alert alert-sm alert-success">Sua senha foi redefinida!</div>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field type="email" name="email" placeholder="E-mail" />
              <Field type="password" name="password" placeholder="Senha" />
              <NavLink to="/forgot-password">Esqueceu sua senha?</NavLink>
              <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                {isSubmitting ? 'Entrando...' : 'Entrar'}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Login;
