import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function EventList({ events, linkTo, onSelectEvent = () => {} }) {
  function handleLinkTo(event) {
    if (typeof linkTo === 'function') {
      return linkTo(event);
    }

    return linkTo;
  }

  return (
    <div className="panel">
      <div className="list">
        {events.length === 0 ? (
          <div className="list-item">Nenhum evento associado a esta conta.</div>
        ) : (
          events.map((event) => (
            <Link
              key={event.id}
              to={handleLinkTo(event)}
              onClick={() => onSelectEvent(event)}
              className="list-item"
            >
              <div className="list-image">
                <img src={event.image.url} alt={event.name} />
              </div>
              <div className="flex">
                <div className="title">{event.name}</div>
                <div className="description">
                  {event.address.city}, {event.address.state}
                </div>
              </div>
              <div className="flex-align-right">
                <FontAwesomeIcon icon="angle-right" />
              </div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
}

export default EventList;
