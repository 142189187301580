import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTitle from 'hooks/useTitle';

function Success() {
  useTitle('Venda concluída');

  const dispatch = useDispatch();
  const { paymentMethod } = useSelector(({ order }) => order);
  const history = useHistory();

  const [title, description] = (() => {
    switch (paymentMethod.constant) {
      case 'CASH':
        return [
          'A venda foi realizada com sucesso.',
          'Dentro de alguns instantes os participantes receberão os ingressos via e-mail.',
        ];
      case 'COURTESY':
        return [
          'As cortesias foram geradas com sucesso.',
          'Dentro de alguns instantes os participantes receberão as cortesias via e-mail.',
        ];
      case 'BOLETO':
        return [
          'Boleto gerado e ingressos reservados com sucesso',
          'Dentro de alguns instantes o cliente receberá um e-mail com o boleto para realizar o pagamento.',
        ];
      case 'CREDIT_CARD':
        return [
          'Link de pagamento gerado com sucesso.',
          'Dentro de alguns instantes o cliente receberá um e-mail com o link de pagamento via cartão de crédito.',
        ];
      default:
        return ['Enviado com sucesso.', 'Dentro de alguns instantes o cliente receberá um e-mail.'];
    }
  })();

  useEffect(() => {
    let timeout = setTimeout(() => {
      history.push('/order');
      dispatch({ type: 'SET_ORDER_INITIAL' });
    }, 15000);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div id="order-success" className="page">
      <FontAwesomeIcon icon="check-circle" />
      <p className="title">{title}</p>
      <p className="description">{description}</p>
      <Link to="/order" className="btn btn-primary">
        Voltar para o início
      </Link>
    </div>
  );
}

export default Success;
