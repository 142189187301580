import React from 'react';
import useTitle from 'hooks/useTitle';

function Maintenance() {
  useTitle('Manutenção');

  return (
    <div id="application">
      <section id="page-not-found" className="page">
        <p className="title">
          Sorry!
          <span role="img" aria-label="peace">
            ️ 😓
          </span>
        </p>
        <p className="description">
          {process.env.REACT_APP_MAINTENANCE_MODE_MESSAGE ??
            'Estamos atualizando a nossa plataforma de promoters. Estaremos on-line dentro de algumas horas.'}
        </p>
      </section>
    </div>
  );
}

export default Maintenance;
