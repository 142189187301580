import React from 'react';
import useTitle from 'hooks/useTitle';

function PageNotFound() {
  useTitle('Página não encontrada');

  return (
    <section id="page-not-found" className="page">
      <p className="title">
        Ooops!
        <span role="img" aria-label="peace">
          ️ 🙈
        </span>
      </p>
      <p className="description">Não encontramos a página que você esta procurando.</p>
      <br />
      <a href="/" className="btn btn-lg btn-primary">
        Ir para o início
      </a>
    </section>
  );
}

export default PageNotFound;
