import React from 'react';
import logo from 'assets/images/logo-small.png';

function Splash() {
  return (
    <div className="splash">
      <div className="logo">
        <div className="animation" />
        <img src={logo} alt="UP Tickets logo" />
      </div>
    </div>
  );
}

export default Splash;
