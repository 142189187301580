const initialState = {
  status: 'AUTHENTICATING',
  user: null,
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case 'AUTHENTICATING':
      return {
        ...state,
        status: 'AUTHENTICATING',
      };
    case 'LOGIN':
      return {
        status: 'LOGGED_IN',
        user: action.user ?? {},
      };
    case 'LOGOUT':
      return {
        status: 'LOGGED_OUT',
        user: null,
      };
    default:
      return state;
  }
};

export default authentication;
