import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import axios from 'modules/axios';
import Field from 'components/Field';

const initialValues = {
  email: '',
};

const validationSchema = object().shape({
  email: string().required('Preencha seu e-mail.').email('Preencha com um e-mail válido'),
});

function ForgotPassword() {
  const [status, setStatus] = useState('READY');

  function handleOnSubmit(
    values,
    { setSubmitting, setFieldError, setTouched, setErrors, resetForm }
  ) {
    axios
      .post('/promoter/forgot-password', {
        email: values.email,
        base_url: window.location.origin,
      })
      .then(() => {
        setStatus('SUCCESS');
        resetForm();
      })
      .catch((error) => {
        if (error.response?.status === 422) {
          Object.keys(error.response.data.errors).forEach((inputName) => {
            const errorMessage = error.response.data.errors[inputName].join('\r\n');
            setFieldError(inputName, errorMessage);
          });
        }

        setSubmitting(false);
      })
      .finally(() => setSubmitting(false));
  }

  return (
    <div id="login">
      <div className="content">
        <h1 className="title" style={{ marginTop: 0, marginBottom: '0.2rem' }}>
          Recuperação de senha
        </h1>
        <h5 className="description">
          Insira seu e-mail que você usou no cadastro. Nós mandaremos um e-mail com seu nome de
          usuário e um link para você redefinir sua senha.
        </h5>
        {status === 'SUCCESS' && (
          <div className="alert alert-sm alert-success">
            Enviamos seu link de redefinição de senha por e-mail!
          </div>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field type="email" name="email" placeholder="E-mail" />
              <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                {isSubmitting ? 'Enviando...' : 'Enviar e-mail'}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ForgotPassword;
