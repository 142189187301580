const initialState = {
  event: null,
  paymentMethod: '',
  ticketCategories: [],
  cart: null,
  tickets: [],
  price: 0,
  quantity: 0,
};

function setOrderCart(state) {
  const { event, paymentMethod } = state;

  let ticketCategories = { ...event.ticket_categories };

  // Merge "promoter_available_tickets" data inside the coresponding "ticket" object.
  ticketCategories = event.ticket_categories.map((ticketCategory) => {
    return {
      ...ticketCategory,
      tickets: ticketCategory.tickets.reduce((acc, ticket) => {
        let nextTicket = ticket;

        event.promoter_available_tickets.forEach((availableTicket) => {
          if (
            availableTicket.ticket_id === ticket.id &&
            availableTicket.payment_method_id === paymentMethod.id &&
            availableTicket.available_quantity > 0
          ) {
            nextTicket = {
              ...ticket,
              original_available_quantity: availableTicket.available_quantity,
              available_quantity: availableTicket.available_quantity,
              used_quantity: availableTicket.used_quantity,
            };
          }
        });

        acc.push(nextTicket);

        return acc;
      }, []),
    };
  });

  // Se o metódo de pagamento for CASH ou COURTESY, *REMOVE* todos os "tickets" que não
  // possuem o campo "available_quantity".
  if (paymentMethod.constant === 'CASH' || paymentMethod.constant === 'COURTESY') {
    ticketCategories = ticketCategories.map((ticketCategory) => {
      return {
        ...ticketCategory,
        tickets: ticketCategory.tickets.filter(
          (ticket) => ticket.available_quantity && ticket.available_quantity > 0
        ),
      };
    });
    //
  } else {
    // Se não, *REMOVE* todos os "tickets" que tem o "status" diferente de "AVAILABLE"
    // ou que não tem "available_tickets".
    ticketCategories = ticketCategories.map((ticketCategory) => {
      return {
        ...ticketCategory,
        tickets: ticketCategory.tickets.filter((ticket) => {
          if (ticket.event_id === 134 && (ticket.status_id === 1 || ticket.status_id === 2)) {
            return true;
          }

          return (
            ticket.status_id === 1 || (ticket.available_quantity && ticket.available_quantity > 0)
          );
        }),
      };
    });
  }

  // Remove all categories without tickets
  ticketCategories = ticketCategories.filter((ticketCategory) => ticketCategory.tickets.length > 0);

  // Build the Cart
  const cart = ticketCategories.reduce((acc, ticketCategory) => {
    ticketCategory.tickets.forEach((ticket) => {
      acc[ticket.id] = {
        ...ticket,
        ticket_category_name: ticketCategory.name,
        quantity: 0,
        attendees: [],
      };
    });

    return acc;
  }, {});

  return {
    ...state,
    ticketCategories,
    cart,
  };
}

function updateOrderCartTicket(state, action) {
  const cartTicket = { ...state.cart[action.ticketId] };

  const price =
    action.accountType === 'increase'
      ? state.price + +cartTicket.price * cartTicket.minimum_quantity
      : state.price - +cartTicket.price * cartTicket.minimum_quantity;

  const quantity =
    action.accountType === 'increase'
      ? state.quantity + cartTicket.minimum_quantity
      : state.quantity - cartTicket.minimum_quantity;

  let tickets = [...state.tickets];

  if (action.accountType === 'increase') {
    cartTicket.quantity += cartTicket.minimum_quantity;
    if (cartTicket.hasOwnProperty('available_quantity')) {
      cartTicket.available_quantity -= cartTicket.minimum_quantity;
    }

    for (let i = 0; i < cartTicket.minimum_quantity; i++) {
      let ticketWithUsers = {
        id: cartTicket.id,
        name: cartTicket.ticket_category_name + ' - ' + cartTicket.name,
        price: cartTicket.price,
        user_first_name: '',
        user_last_name: '',
        user_email: '',
      };

      if (process.env.NODE_ENV === 'development') {
        ticketWithUsers.user_first_name = 'William';
        ticketWithUsers.user_last_name = 'Fernandes';
        ticketWithUsers.user_email = 'wfernandes@live.com';
      }

      tickets.push(ticketWithUsers);
    }
  } else {
    cartTicket.quantity -= cartTicket.minimum_quantity;
    if (cartTicket.hasOwnProperty('available_quantity')) {
      cartTicket.available_quantity += cartTicket.minimum_quantity;
    }

    for (let i = 0; i < cartTicket.minimum_quantity; i++) {
      const lastTicketIndex = tickets.map((ticket) => ticket.id).lastIndexOf(action.ticketId);

      if (lastTicketIndex === -1) {
        continue;
      }

      tickets.splice(lastTicketIndex, 1);
    }
  }

  return {
    ...state,
    cart: {
      ...state.cart,
      [action.ticketId]: cartTicket,
    },
    tickets,
    price,
    quantity,
  };
}

const order = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ORDER_EVENT':
      return {
        ...initialState,
        event: action.event,
      };
    case 'SET_ORDER_PAYMENT_METHOD':
      return {
        ...initialState,
        event: state.event,
        paymentMethod: action.paymentMethod,
      };
    case 'UPDATE_ORDER_PAYMENT_METHOD_PAYER':
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          payer: {
            ...state.paymentMethod.payer,
            ...action.fields,
          },
        },
      };
    case 'SET_ORDER_CART':
      return setOrderCart(state);
    case 'UPDATE_ORDER_CART_TICKET':
      return updateOrderCartTicket(state, action);
    case 'UPDATE_ORDER_TICKET':
      return {
        ...state,
        tickets: state.tickets.map((ticket, index) => {
          if (action.index === index) {
            ticket[action.name] = action.value;
          }

          return ticket;
        }),
      };
    case 'SET_ORDER_INITIAL':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default order;
