import React from 'react';
import { Switch, Route, NavLink, Redirect, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Profile from 'pages/Profile';
import Order from 'pages/Order';
import Reports from 'pages/Reports';
import PageNotFound from 'pages/PageNotFound';

const routes = [
  {
    path: '/profile',
    icon: 'user',
    title: 'Minha conta',
  },
  {
    path: '/order',
    icon: 'ticket-alt',
    title: 'Vender',
  },
  {
    path: '/reports',
    icon: 'chart-column',
    title: 'Relatórios',
  },
];

function Panel() {
  const matchOrderRoute = useRouteMatch('/order/*');

  function isOrderRoute() {
    return !matchOrderRoute || matchOrderRoute.url === '/order/events';
  }

  return (
    <div id="application">
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/order" />}></Route>
        <Route path="/profile">
          <Profile />
        </Route>
        <Route path="/order">
          <Order />
        </Route>
        <Route path="/reports">
          <Reports />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
      {isOrderRoute() && (
        <nav id="menu">
          {routes.map((route, index) => (
            <NavLink key={index} to={route.path} className="link" activeClassName="active">
              <FontAwesomeIcon icon={route.icon} />
              {route.title}
            </NavLink>
          ))}
        </nav>
      )}
    </div>
  );
}

export default Panel;
