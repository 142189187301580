/* eslint-disable */
import React from 'react';
import { TextBlock, TextRow, RoundShape } from 'react-placeholder/lib/placeholders';

function Placeholder({ children }) {
  return <div className="placeholder show-loading-animation">{children}</div>;
}

export function ListPlaceholder({ items = 2 }) {
  return (
    <Placeholder>
      <div className="panel">
        <div className="list">
          {Array(items)
            .fill(0)
            .map((value, index) => (
              <div key={index} className="list-item">
                <div className="list-image">
                  <RoundShape color="black" />
                </div>
                <div className="text">
                  <TextBlock rows={2} color="black" />
                </div>
              </div>
            ))}
        </div>
      </div>
    </Placeholder>
  );
}

export function DashboardPlaceholder() {
  return (
    <Placeholder>
      <div className="statistics">
        {Array(3)
          .fill(0)
          .map((value, index) => (
            <div key={index} className="statistic">
              <div className="title">
                <TextBlock style={{ width: '70%', marginBottom: '0.4rem' }} color="black" />
              </div>
              <div className="value">
                <TextBlock style={{ width: '40%' }} color="black" />
              </div>
            </div>
          ))}
      </div>
      <div className="panel orders">
        <div className="panel-header">
          <TextBlock style={{ width: '20%' }} color="black" />
        </div>
        <div className="list">
          {Array(3)
            .fill(0)
            .map((value, index) => (
              <div key={index} className="list-item display-flex">
                <div className="flex">
                  <TextRow style={{ width: '80%', marginTop: 0 }} color="black" />
                  <TextRow style={{ width: '60%' }} color="black" />
                </div>
                <TextRow
                  style={{ width: '30%', height: '1.4rem', marginTop: 0, marginLeft: 'auto' }}
                  color="black"
                />
              </div>
            ))}
        </div>
      </div>
      <div className="panel reports">
        <div className="panel-header">
          <TextBlock style={{ width: '20%' }} color="black" />
        </div>
        <div className="panel-body">
          <RoundShape
            style={{
              minWidth: '140px',
              maxWidth: '140px',
              height: '140px',
              marginRight: '0.6rem',
              borderRadius: '50%',
            }}
            color="black"
          />
          <div className="list">
            {Array(3)
              .fill(0)
              .map((value, index) => (
                <div key={index} className="list-item">
                  <div className="flex">
                    <TextRow style={{ width: '60%' }} color="black" />
                    <TextRow style={{ width: '40%' }} color="black" />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Placeholder>
  );
}

export function OrderPlaceholder() {
  return (
    <Placeholder>
      <div className="statistics">
        {Array(3)
          .fill(0)
          .map((value, index) => (
            <div key={index} className="statistic">
              <div className="title">
                <TextBlock style={{ width: '70%', marginBottom: '0.4rem' }} color="black" />
              </div>
              <div className="value">
                <TextBlock style={{ width: '40%' }} color="black" />
              </div>
            </div>
          ))}
      </div>
      <div className="panel orders">
        <div className="panel-header">
          <TextBlock style={{ width: '20%' }} color="black" />
        </div>
        <div className="list">
          {Array(2)
            .fill(0)
            .map((value, index) => (
              <div key={index} className="list-item display-flex">
                <div className="flex">
                  <TextRow style={{ width: '80%', marginTop: 0 }} color="black" />
                  <TextRow style={{ width: '60%' }} color="black" />
                </div>
                <TextRow
                  style={{ width: '30%', height: '1.4rem', marginTop: 0, marginLeft: 'auto' }}
                  color="black"
                />
              </div>
            ))}
        </div>
      </div>
    </Placeholder>
  );
}
