import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Events from 'pages/Order/Events';
import Cart from 'pages/Order/Cart';
import Attendees from 'pages/Order/Attendees';
// import Boleto from 'pages/Order/Boleto';
import CreditCard from 'pages/Order/CreditCard';
import PaymentMethods from 'pages/Order/PaymentMethods';
import Checkout from 'pages/Order/Checkout';
import Success from 'pages/Order/Success';
import Pix from './Pix';

function Order() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/events`}>
        <Events />
      </Route>
      <Route path={`${path}/payment-methods`}>
        <PaymentMethods />
      </Route>
      <Route path={`${path}/cart`}>
        <Cart />
      </Route>
      <Route path={`${path}/attendees`}>
        <Attendees />
      </Route>
      {/* <Route path={`${path}/boleto`}>
        <Boleto />
      </Route> */}
      <Route path={`${path}/pix`}>
        <Pix />
      </Route>
      <Route path={`${path}/credit-card`}>
        <CreditCard />
      </Route>
      <Route path={`${path}/checkout`}>
        <Checkout />
      </Route>
      <Route path={`${path}/success`}>
        <Success />
      </Route>
      <Route render={() => <Redirect to={`${path}/events`} />} />
    </Switch>
  );
}

export default Order;
