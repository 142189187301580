import { createStore, combineReducers, applyMiddleware } from 'redux';
import user from 'state/reducers/user';
import order from 'state/reducers/order';

const reducers = combineReducers({
  user,
  order,
});

const middlewares = [];

if (process.env.NODE_ENV === 'development') {
  const createLogger = require('redux-logger').createLogger;

  const logger = applyMiddleware(
    createLogger({
      collapsed: true,
    }),
  );

  middlewares.push(logger);
}

const store = createStore(reducers, ...middlewares);

export default store;
