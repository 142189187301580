import React, { useState } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Events from './Events';
import Dashboard from './Dashboard';
import Order from './Order';

function Reports() {
  const { path } = useRouteMatch();
  const [selectedOrder, setSelectedOrder] = useState(null);

  function handleOnSelectOrder(order) {
    setSelectedOrder(order);
  }

  return (
    <Switch>
      <Route path={`${path}/events`}>
        <Events />
      </Route>
      <Route path={`${path}/dashboard/:eventId`}>
        <Dashboard onSelectOrder={handleOnSelectOrder} />
      </Route>
      <Route path={`${path}/order/:orderId`}>
        <Order order={selectedOrder} />
      </Route>
      <Route render={() => <Redirect to={`${path}/events`} />} />
    </Switch>
  );
}

export default Reports;
